<template>
  <slice
    class="slice--cta-image"
    :class="[
      slice.primary.full_page ? '' : 'container',
      slice.primary.full_page ? 'full' : '',
      `text--${slice.primary.text_position}`,
      slice.primary.no_shadow ? 'no-shadow' : '',
    ]"
    :data-slice-type="slice.slice_type"
    :data-slice-variation="slice.variation"
  >
    <div class="row-md justify-center">
      <div
        :class="slice.primary.full_page ? 'col-md-12' : 'col-md-10 col-xlg-12'"
      >
        <nuxt-link
          :to="link"
          :href="link"
          data-cy-link
          @click.native="activateLoading"
        >
          <div v-if="firstSlice" class="slice__image p-relative" data-cy-image>
            <image-simple
              :image="
                slice.primary.full_page
                  ? slice.primary.image.full
                  : slice.primary.image
              "
              class="d-none d-md-block"
            />
            <image-simple
              :image="
                slice.primary.full_page
                  ? slice.primary.image.full_mobile
                  : slice.primary.image.mobile
              "
              :widths="[320, 414, 640, 769]"
              class="d-md-none"
            />
          </div>
          <div v-else class="slice__image p-relative" data-cy-image>
            <lazy-image
              ref="imageDesktop"
              :image="
                slice.primary.full_page
                  ? slice.primary.image.full
                  : slice.primary.image
              "
              class="d-none d-md-block"
            />
            <lazy-image
              :image="
                slice.primary.full_page
                  ? slice.primary.image.full_mobile
                  : slice.primary.image.mobile
              "
              :widths="[320, 414, 640, 769]"
              class="d-md-none"
            />
          </div>

          <div class="slice__content">
            <p
              v-if="slice.primary.accroche"
              class="mb-0-6 text-medium w-600 mb-0-6"
              :class="[
                slice.primary.text_color === 'jaune'
                  ? 'text-color-yellow'
                  : 'text-color-tertiary',
              ]"
            >
              {{ slice.primary.accroche }}
            </p>

            <p
              class="w-900 mb-1"
              :class="[
                slice.primary.full_page ? 'h1' : 'h3',
                slice.primary.text_color === 'jaune'
                  ? 'text-color-yellow'
                  : 'text-color-tertiary',
              ]"
            >
              {{ slice.primary.title }}
            </p>

            <div
              class="d-flex"
              :class="[
                slice.primary.full_page
                  ? 'justify-center'
                  : slice.primary.text_position === 'droite'
                  ? 'justify-end'
                  : '',
                slice.primary.text_position === 'gauche'
                  ? 'justify-md-start'
                  : '',
                slice.primary.text_position === 'centre'
                  ? 'justify-md-center'
                  : '',
                slice.primary.text_position === 'droite'
                  ? 'justify-md-end'
                  : '',
              ]"
            >
              <div>
                <primary-button
                  ref="link"
                  type="button"
                  :text="slice.primary.button_label"
                  with-arrow
                  :aspect="
                    slice.primary.text_color === 'jaune'
                      ? 'yellow'
                      : slice.primary.full_page
                      ? 'dark'
                      : 'light'
                  "
                  :loading="loadingButton"
                />
              </div>
            </div>
          </div>
        </nuxt-link>
      </div>
    </div>
  </slice>
</template>

<script>
import Slice from '@/components/Slices/Slice'
import PrimaryButton from '@/components/buttons/Primary'
import ImageSimple from '@/components/Image/ImageSimple'
import LazyImage from '@/components/Image/LazyImage'

export default {
  name: 'CTAImageSlice',
  components: {
    Slice,
    PrimaryButton,
    ImageSimple,
    LazyImage,
  },
  props: {
    slice: { type: Object, required: true },
    firstSlice: { type: Boolean, default: false },
  },
  data: () => ({ loadingButton: false }),
  computed: {
    link() {
      return this.$contextPrismicPath(this.slice.primary.link)
    },
  },
  methods: {
    activateLoading() {
      this.loadingButton = true
    },
  },
}
</script>

<style lang="scss">
.slice--cta-image {
  .slice__image {
    background: black;

    > * {
      opacity: 0.6;
    }
  }

  &.no-shadow {
    .slice__image > * {
      opacity: 1;
    }
  }

  .slice__content {
    position: absolute;
    bottom: calc(var(--spacing) * 2);
    left: calc(var(--spacing) * 2);
    right: calc(var(--spacing) * 2);

    @include mq($from: tablet) {
      bottom: calc(var(--spacing) * 3);
      left: calc(var(--spacing) * 3);
      width: rem(580px);
    }
  }

  &.text--droite {
    .slice__content {
      @include mq($from: tablet) {
        left: 0;
        right: calc(var(--spacing) * 3);
      }
    }
  }

  &.text--centre {
    .slice__content {
      @include mq($from: tablet) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &.full {
    .slice__image {
      .lazy-image,
      .image--simple {
        padding-top: 0 !important;
        height: 100vh;

        > img {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: auto;
          height: auto;
          min-width: 100%;
          min-height: 100%;
          max-width: none;
        }
      }

      .image--simple {
        overflow: hidden;
      }
    }

    .slice__content {
      bottom: calc(var(--spacing) * 5);
      left: 0;
      right: 0;
      padding: 0 var(--spacing);
      text-align: center;
      transform: none;

      @include mq($from: tablet) {
        width: auto;
        bottom: rem(150px);
        padding: 0 calc(100% / #{$grid-columns} * 7) 0 calc(var(--spacing) * 2);
        text-align: right;
      }
    }

    &.text--centre {
      .slice__content {
        @include mq($from: tablet) {
          padding: 0 calc(var(--spacing) * 2);
          text-align: center;
        }
      }
    }

    &.text--droite {
      .slice__content {
        @include mq($from: tablet) {
          padding: 0 calc(var(--spacing) * 2) 0
            calc(100% / #{$grid-columns} * 7);
          text-align: left;
        }
      }
    }
  }
}
</style>
